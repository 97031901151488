require('dotenv').config();

const env = process.env;


export const DASHBOARD_COOKIE_EXPIRE_TIME = 20;
// export const DASHBOARD_COOKIE_EXPIRE_TIME = new Date(new Date().getTime() + 15 * 60 * 1000);

export const JWT_PASS = env.REACT_APP_JWT_PASS || '';

export const REACT_APP_BASE_URL = env.REACT_APP_BASE_URL || '';

export const REACT_APP_ENABLE_FB_TESTING = env.REACT_APP_ENABLE_FB_TESTING || false;

export const GA_ID = env.REACT_APP_GA_ID || '';
