import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import Logo from '../assets/img/everythingdata-logo.png';
import Google_Logo from '../assets/img/google_g_logo.png';
import AuthApi from '../api/authApi';
import { DASHBOARD_COOKIE_EXPIRE_TIME, REACT_APP_ENABLE_FB_TESTING } from '../config/system/systemConfig';
import { getCookie, setCookie } from '../utils/cookiesUtils';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import { decodeJWT } from '../utils/jwtUtil';


export default function Login(props: any) {
  const [login, setLogin] = useState(false);
  const [loginUrl, setLoginUrl] = useState('');
  const [callBack, setCallback] = useState('');

  let [testToken, setTestToken] = useState('');

  const checkLogin = () => {
    try {
      let { token } = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }) as {
        token: string;
        // callback_url: string;
        // state: string;
      };

      const urlParams = new URLSearchParams(window.location.search);
      const team_accept_uri = urlParams.get('team_accept_uri');

      if (token) {
        setCookie('dashboard_token', token, DASHBOARD_COOKIE_EXPIRE_TIME);
      }

      let authorization = getCookie('dashboard_token');
      
      if (authorization) {
        if (team_accept_uri) {
          const team_accept_path = new URL(team_accept_uri).pathname;
          setLogin(true);
          setCallback(team_accept_path);
        } else {
          setLogin(true);
          setCallback('/connections');
        }
      } else {
        setLogin(false);
        setUrl();
      }
    } catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const setUrl = async () => {
    try {
      const redirect_uri = window.location.href;
      await getGoogleLoginUrl(redirect_uri);
    } catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const getGoogleLoginUrl = async (redirect_uri: string) => {
    try {
      const response = await AuthApi.getGoogleLoginUrl(redirect_uri);
      if (response.success) {
        const data = (response as SuccessResponse).data;
        setLoginUrl(data.login_url);
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  // Facebook login testing
  const handleTestLogin = () => {
    try {
      let json_token = decodeJWT(testToken);

      if (json_token?.user_id === '621a2f85040174000aaf58b7' && json_token?.team_id === '621a2f85040174000aaf58b9') {
        setCookie('dashboard_token', testToken, DASHBOARD_COOKIE_EXPIRE_TIME);

        let authorization = getCookie('dashboard_token');
        if (authorization) {
          setLogin(true);
          setCallback('/connections');
        } else {
          setLogin(false);
          setUrl();
        }
      } else {
        toast.error('INCORRECT PASSCODE');
      }
    } catch (error) {
      toast.error('Error decoding token');
    }
  };

  useEffect(() => {
    try {
      checkLogin();
    } catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  }, [props]);

  return (
    <>
      {login ? (
        <Redirect to={callBack ? callBack : '/'} />
      ) : (


        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg shadow-md w-full max-w-lg"> {/* Increased max width of the inner box */}
            {/* Logo at the top */}
            <div className="flex justify-center" style={{ marginBottom: '4rem' }}>
              <img src={Logo} alt="Company Logo" className="w-auto" style={{ height: '100px' }} />
            </div>

            {/* Sign in or Create an account message */}
            <div className="text-center mb-6">
              <h2 className="text-xl font-medium text-gray-800">Sign in or Create an account</h2>
            </div>

            {/* Google button */}
            <div className="flex justify-center mb-6">
              <button
                className={
                  loginUrl
                    ? 'flex w-full justify-center items-center bg-white py-3 px-6 border border-gray-300 rounded-lg shadow-sm hover:shadow-inner focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-all'
                    : 'flex w-full justify-center bg-gray-500 py-3 px-6 cursor-not-allowed'
                }
                type="button"
                onClick={() => {
                  window.location.href = loginUrl;
                }}
                disabled={loginUrl ? false : true}
              >
                <img src={Google_Logo} alt="Google G Logo" width="30" height="30" className="mr-3" />
                <span className="text-black font-medium text-lg">Continue with Google</span>
              </button>
            </div>


            {/* Facebook Testing Login */}
            {REACT_APP_ENABLE_FB_TESTING === 'true' && (
              <div className="mt-8">
                <input
                  type="text"
                  value={testToken}
                  onChange={(e) => setTestToken(e.target.value)}
                  placeholder="Enter FB testing passcode:"
                  className="px-4 py-2 border rounded-md text-gray-700 w-full"
                />
                <button
                  onClick={handleTestLogin}
                  className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-md w-full hover:bg-blue-600"
                >
                  Test Login
                </button>
              </div>
            )}

          </div>
        </div>

      )}
    </>
  );
}

