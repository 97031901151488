
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../models/system/errorResponse';


export function handleAxiosError(error: any): ErrorResponse {
  try {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        const responseData = axiosError.response.data as ErrorResponse | undefined;

        if (responseData && responseData.error) {
          return responseData;
        }
      }
    }

    return {
      status: 500,
      success: false,
      message: error.message || 'Uncaught Error',
      error: {
        error_code: 'UNCAUGHT_ERROR',
        error_event: 'uncaught-error',
        error_location: 'handleAxiosError',
      },
    };
  } 
  catch (error) {
    return {
      status: 500,
      success: false,
      message: 'An error occurred while processing the error',
      error: {
        error_code: 'INTERNAL_ERROR',
        error_event: 'internal-error',
        error_location: 'handleAxiosError',
      },
    };
  }
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
