import axios from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';

const API_URL = process.env.REACT_APP_BASE_URL;

class AuthApi {

    async getGoogleLoginUrl(url: string) {
        try {
            const response = await axios.get(API_URL + '/auth/google/login_url?redirect_url=' + url);
            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getUserTeams(userId: string) {
        try {
            const response = await axios.get(`${API_URL}/auth/${userId}/teams/`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async selectTeam(teamId: any, userId: any, callbackUrl: any, team_accept?: string) {
        try {
            const data =
            {
                teamId: teamId,
                userId: userId,
                callbackUrl: callbackUrl,
            };

            const response = await axios.post(`${API_URL}/auth/me/teams/select?integration=dashboard`, data, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

}

export default new AuthApi();
