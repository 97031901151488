import { getCookie } from '../utils/cookiesUtils';

export default function authHeader() {
  try {
    const token = getCookie('dashboard_token');

    if (token) {
      return { 'Authorization': `Bearer ${token}` };
    }
  }
  catch (error) {
    throw error;
  }
}
