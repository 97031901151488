import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import Login from './Pages/Login';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import SelectTeams from './Pages/SelectTeams';
import TeamAccept from './Pages/Team_Accept';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import { GA_ID } from './config/system/systemConfig';
import 'react-toastify/dist/ReactToastify.css';

const Layout = lazy(() => import('./components/Common/Layout'));

function Analytics() {
  const location = useLocation();

  useEffect(() => {
    const gaId = GA_ID;
    if (gaId) {
      ReactGA.initialize(gaId);
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
    // if (redirectUrl) {
    //   window.location.href = redirectUrl;
    // }
  }, []);

  return (
    <div className="App font-system">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Analytics />
        <Switch>
          <Route path="/select_teams" component={SelectTeams} exact={true} />
          <Route path="/login" component={Login} exact={true} />
          <PrivateRoute path="/team_accept/:invite_id" component={TeamAccept} exact={true} />
          <Suspense fallback={<div>Loading...</div>}>
            <PrivateRoute component={Layout} />
          </Suspense>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
