import Cookies from 'js-cookie';
import { config } from 'dotenv';
config();

export function getAllCookies() {
  try {
    return Cookies.get();
  } catch (error) {
    throw error;
  }
}

export function setCookie(
  key: string,
  value: string,
  expireTime: number | Date
) {
  try {
    const options: any = {
      expires: expireTime,
      sameSite: 'Lax',  // Default to 'Lax' for better security
    };

    if (window.location.protocol === 'https:') {
      options.secure = true;  // Only send cookies over HTTPS
    }

    if (process.env.REACT_APP_DOMAIN && !window.location.hostname.includes('localhost')) {
      options.domain = process.env.REACT_APP_DOMAIN;
      options.sameSite = 'None';  // Required for cross-domain cookies
      options.secure = true;      // SameSite=None requires Secure
    }

    return Cookies.set(key, value, options);
  } catch (error) {
    throw error;
  }
}

export function getCookie(key: string) {
  try {
    return Cookies.get(key);
  } catch (error) {
    throw error;
  }
}

export function removeCookie(key: string) {
  try {
    const options: any = {};

    if (process.env.REACT_APP_DOMAIN && !window.location.hostname.includes('localhost')) {
      options.domain = process.env.REACT_APP_DOMAIN;
      options.sameSite = 'None';  // Required for cross-domain cookies
      options.secure = true;      // SameSite=None requires Secure
    }

    return Cookies.remove(key, options);
  } catch (error) {
    throw error;
  }
}