import jwt from 'jsonwebtoken';
import { JWT_PASS } from '../config/system/systemConfig';
require('dotenv').config();

export function createToken(payload: object, expires_in: string | number | null): string {
  try {
    const options: jwt.SignOptions = {};

    if (expires_in !== null) {
      options.expiresIn = expires_in;
    }

    const token = jwt.sign(payload, JWT_PASS, options);

    return token;
  }
  catch (error) {
    throw error; 
  }
}

export function decodeJWT(token: string): Record<string, any> | null {
  try {
    const decodedToken = jwt.verify(token, JWT_PASS);

    return decodedToken as Record<string, any>;
  } 
  catch (error) {
    throw error;
  }
}

export function verifyToken(token: string): any {
  try {
    return jwt.verify(token, JWT_PASS);
  }
  catch (error) {
    throw error;
  }
}

export function verifyLookerToken(token: string) {
  try {
    if (!token) return false;
    return jwt.verify(token, JWT_PASS, { algorithms: ['HS256'] });
  }
  catch (error) {
    throw error;
  }
}


